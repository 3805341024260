//Primary color
$primary-color: #17763A;
$primary50-color: #C8D7D8;
$black-color: #1B1F24;
$white-color: #D0D5DD;     
$tertiary-color: #ffe493;
$sub-text-color: #475467;
$grey-color: #D0D5DD;
$grey-light-color: #EAECF0;
$surface-grey-color: #F9FAFB;

//typography
$title-color: #a0a0a0;
$subtitle-color: #5c5c5c;
$text-color: #1b1f24;
//$background-color: #fdfdfd;
$background-color: #fff;

//Footer
$footer-background-color-top: #000000;
$footer-background-color-bottom: #1e1d1d;

//Header
$header-background-color: #000000;
$header-button-selected-text-color: #000000;
$header-button-selected-background-color: #dedffd;

//PageTitle
$page-title-color: #1b1f24;
$page-subtitle-color: #475467;

//scrollbar
$scrollbar-background-color: #f7f7f7;
$scrollbar-color: rgba(31, 93, 54, 0.607);
$scrollbar-hover-color: $primary-color;
$scrollbar-background-shadow-color: #f7f7f7;

//Modal
$title-modal-color: #000;

:root {
    --purple-lib-primary-color: #17763A;
    --purple-lib-font-size: 14px;
    --purple-lib-font-family: "Inter", sans-serif !important;
    --purple-lib-body-background: #fdfdfd;
    --purple-lib-disabled-color: fade(#000, 55%);

    //PageTitle
    --purple-lib-page-title-color: #1b1f24;
    --purple-lib-page-subtitle-color: #475467;

    //SideMenu
    --purple-lib-side-menu-background: #1B1F24;
    --purple-lib-side-menu-not-selected-text-color: #fff;
    --purple-lib-side-menu-selected-text-color: #fff;
    --purple-lib-side-menu-selected-background-color: #17763A;
    --purple-lib-side-main-menu-text-color: #D0D5DD;
}
