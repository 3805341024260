//@import "/node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "/node_modules/purple-lib/styles/style.less";

@primary-color    : #17763A;
@tooltip-bg       : @primary-color;
@highlight-color  : @primary-color;
@link-color       : @primary-color;
@link-hover-color : @primary-color;
@link-active-color: @primary-color;
@font-size-base   : 14px;
@font-family      : 'Inter', sans-serif;
@body-background  : #fdfdfd;
@disabled-color: fade(#000, 55%);
