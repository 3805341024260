
.icon-accountingview_icon:before { content: '\e800'; } /* '' */
.icon-agreement:before { content: '\e801'; } /* '' */
.icon-aibstatistics_icon:before { content: '\e802'; } /* '' */
.icon-any_res:before { content: '\e803'; } /* '' */
.icon-arrowdown:before { content: '\e804'; } /* '' */
.icon-arrowleft:before { content: '\e805'; } /* '' */
.icon-arrownarrowleft:before { content: '\e806'; } /* '' */
.icon-arrowright-1:before { content: '\e807'; } /* '' */
.icon-arrowright:before { content: '\e808'; } /* '' */
.icon-arrowup:before { content: '\e809'; } /* '' */
.icon-backtoback:before { content: '\e80a'; } /* '' */
.icon-biogass:before { content: '\e80b'; } /* '' */
.icon-brokers_icon:before { content: '\e80c'; } /* '' */
.icon-calendar:before { content: '\e80d'; } /* '' */
.icon-bankguarantee_icon:before { content: '\e80e'; } /* '' */
.icon-beaker:before { content: '\e80f'; } /* '' */
.icon-brokerportfolio:before { content: '\e810'; } /* '' */
.icon-chevron-left:before { content: '\e811'; } /* '' */
.icon-checkdone:before { content: '\e812'; } /* '' */
.icon-chevron-down:before { content: '\e813'; } /* '' */
.icon-chevron-selector-vertical:before { content: '\e814'; } /* '' */
.icon-chevron-up:before { content: '\e815'; } /* '' */
.icon-chevron-right:before { content: '\e816'; } /* '' */
.icon-checksquare:before { content: '\e817'; } /* '' */
.icon-contractmanagement_icon:before { content: '\e818'; } /* '' */
.icon-comment:before { content: '\e819'; } /* '' */
.icon-chf:before { content: '\e81a'; } /* '' */
.icon-copy:before { content: '\e81b'; } /* '' */
.icon-check:before { content: '\e81c'; } /* '' */
.icon-counterpart_icon:before { content: '\e81d'; } /* '' */
.icon-dashboard_icon:before { content: '\e81e'; } /* '' */
.icon-creditrisk_icon:before { content: '\e81f'; } /* '' */
.icon-dealslog_icon:before { content: '\e820'; } /* '' */
.icon-dealticket_icon:before { content: '\e821'; } /* '' */
.icon-deliveries:before { content: '\e822'; } /* '' */
.icon-dotshorizontal:before { content: '\e823'; } /* '' */
.icon-dotsvertical:before { content: '\e824'; } /* '' */
.icon-addchild:before { content: '\e825'; } /* '' */
.icon-edit:before { content: '\e826'; } /* '' */
.icon-emptycheck:before { content: '\e827'; } /* '' */
.icon-eur:before { content: '\e828'; } /* '' */
.icon-exchangerate_icon:before { content: '\e829'; } /* '' */
.icon-export:before { content: '\e82a'; } /* '' */
.icon-eye:before { content: '\e82b'; } /* '' */
.icon-file:before { content: '\e82c'; } /* '' */
.icon-filesearch:before { content: '\e82d'; } /* '' */
.icon-filter:before { content: '\e82e'; } /* '' */
.icon-eye-off:before { content: '\e82f'; } /* '' */
.icon-folder:before { content: '\e830'; } /* '' */
.icon-geothermal:before { content: '\e831'; } /* '' */
.icon-gbp:before { content: '\e832'; } /* '' */
.icon-history:before { content: '\e833'; } /* '' */
.icon-import:before { content: '\e834'; } /* '' */
.icon-internalalerts_icon:before { content: '\e835'; } /* '' */
.icon-inbox:before { content: '\e836'; } /* '' */
.icon-invoicemanagement_icon:before { content: '\e837'; } /* '' */
.icon-lightning:before { content: '\e838'; } /* '' */
.icon-linechartup:before { content: '\e839'; } /* '' */
.icon-liquidity_icon:before { content: '\e83a'; } /* '' */
.icon-map:before { content: '\e83b'; } /* '' */
.icon-marketwatch_icon:before { content: '\e83c'; } /* '' */
.icon-minus:before { content: '\e83d'; } /* '' */
.icon-offerrequest_icon:before { content: '\e83e'; } /* '' */
.icon-necs:before { content: '\e83f'; } /* '' */
.icon-mtmprices_icon:before { content: '\e840'; } /* '' */
.icon-ordering:before { content: '\e841'; } /* '' */
.icon-overallbyproduct_icon:before { content: '\e842'; } /* '' */
.icon-overallbytechnologies_icon:before { content: '\e843'; } /* '' */
.icon-resetcolumn:before { content: '\e844'; } /* '' */
.icon-pasteeditor:before { content: '\e845'; } /* '' */
.icon-pendingdeals_icon:before { content: '\e846'; } /* '' */
.icon-piechart:before { content: '\e847'; } /* '' */
.icon-plus:before { content: '\e848'; } /* '' */
.icon-placeholder:before { content: '\e849'; } /* '' */
.icon-percentcircle:before { content: '\e84a'; } /* '' */
.icon-portfolio_icon:before { content: '\e84b'; } /* '' */
.icon-profile:before { content: '\e84c'; } /* '' */
.icon-printer:before { content: '\e84d'; } /* '' */
.icon-powerreporting_icon:before { content: '\e84e'; } /* '' */
.icon-reconciliation_icon:before { content: '\e84f'; } /* '' */
.icon-duplicate-1:before { content: '\e850'; } /* '' */
.icon-save:before { content: '\e851'; } /* '' */
.icon-refresh:before { content: '\e852'; } /* '' */
.icon-scheduler_icon:before { content: '\e853'; } /* '' */
.icon-search:before { content: '\e854'; } /* '' */
.icon-sek:before { content: '\e855'; } /* '' */
.icon-settings:before { content: '\e856'; } /* '' */
.icon-send:before { content: '\e857'; } /* '' */
.icon-summary_icon:before { content: '\e858'; } /* '' */
.icon-sun:before { content: '\e859'; } /* '' */
.icon-table:before { content: '\e85a'; } /* '' */
.icon-templates_icon:before { content: '\e85b'; } /* '' */
.icon-thermal:before { content: '\e85c'; } /* '' */
.icon-traderspnl_icon:before { content: '\e85d'; } /* '' */
.icon-upload:before { content: '\e85e'; } /* '' */
.icon-wind:before { content: '\e85f'; } /* '' */
.icon-water:before { content: '\e860'; } /* '' */
.icon-wavgprices_icon:before { content: '\e861'; } /* '' */
.icon-x-close:before { content: '\e862'; } /* '' */
.icon-clear:before { content: '\e863'; } /* '' */
.icon-merge:before { content: '\e864'; } /* '' */
.icon-riskAssessment_icon:before { content: '\e865'; } /* '' */
.icon-cuteditor:before { content: '\e866'; } /* '' */
.icon-bold:before { content: '\e867'; } /* '' */
.icon-checkcircle:before { content: '\e868'; } /* '' */
.icon-homepage:before { content: '\e869'; } /* '' */
.icon-pnl:before { content: '\e86a'; } /* '' */
.icon-header:before { content: '\e86b'; } /* '' */
.icon-underline:before { content: '\e86c'; } /* '' */
.icon-pnl_evolution_icon:before { content: '\e86d'; } /* '' */
.icon-italic:before { content: '\e86e'; } /* '' */
.icon-phone:before { content: '\e86f'; } /* '' */
.icon-mail:before { content: '\e870'; } /* '' */
.icon-numberinput:before { content: '\e871'; } /* '' */
.icon-textinput:before { content: '\e872'; } /* '' */
.icon-align-right:before { content: '\e873'; } /* '' */
.icon-align-left:before { content: '\e874'; } /* '' */
.icon-align-justify:before { content: '\e875'; } /* '' */
.icon-align-center:before { content: '\e876'; } /* '' */
.icon-resetrow:before { content: '\e877'; } /* '' */
.icon-link:before { content: '\e878'; } /* '' */
.icon-signature:before { content: '\e879'; } /* '' */
.icon-rightsidebar:before { content: '\e87a'; } /* '' */
.icon-alignbottom:before { content: '\e87b'; } /* '' */
.icon-alignleft:before { content: '\e87c'; } /* '' */
.icon-alignhorizontalcentre:before { content: '\e87d'; } /* '' */
.icon-alignright:before { content: '\e87e'; } /* '' */
.icon-aligntoparrow:before { content: '\e87f'; } /* '' */
.icon-distributespacinghorizontal:before { content: '\e880'; } /* '' */
.icon-aligverticalcenter:before { content: '\e881'; } /* '' */
.icon-centervertically:before { content: '\e882'; } /* '' */
.icon-distributespacingvertical:before { content: '\e883'; } /* '' */
.icon-centerhorizontally:before { content: '\e884'; } /* '' */
.icon-centerboth:before { content: '\e885'; } /* '' */
.icon-trash:before { content: '\e886'; } /* '' */
.icon-layers:before { content: '\e887'; } /* '' */
.icon-image:before { content: '\e888'; } /* '' */
.icon-pencil:before { content: '\e889'; } /* '' */
.icon-initials:before { content: '\e88a'; } /* '' */
.icon-insertcolumn:before { content: '\e88b'; } /* '' */
.icon-deleterow-1:before { content: '\e88c'; } /* '' */
.icon-deleterow:before { content: '\e88d'; } /* '' */
.icon-movecolumnleft:before { content: '\e88e'; } /* '' */
.icon-movecolumnright:before { content: '\e88f'; } /* '' */
.icon-moverowup:before { content: '\e890'; } /* '' */
.icon-moverowdown:before { content: '\e891'; } /* '' */
.icon-insertrow:before { content: '\e892'; } /* '' */
.icon-pages:before { content: '\e893'; } /* '' */
